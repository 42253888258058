<template>
      <div class="about-inner inner">
        <div class="section-title text-left">
          <br />
          <h3  class="heading-title" style="color: #211862;">¿Como trabajamos?</h3> 
          <br/>
          <p style="font-weight: 500; font-size: 14px; color: #5c5b5b;">
            En Buddy Technologies, trabajamos con un enfoque centrado en el
            cliente para garantizar que cada proyecto sea un éxito. Nuestro
            equipo de expertos trabaja en estrecha colaboración con los
            clientes para comprender sus necesidades y objetivos y desarrollar
            soluciones de software a medida que se adaptan a sus necesidades.
          </p>
        </div>
        <div class="rn-accordion mt--20">
          <Accordion />
        </div>

        <div class="about-button mt--50">
          <a class="rn-button-style--2 btn_solid" href="#contact"
            >CONVERSEMOS</a
          >
        </div>
      </div>
</template>
<script>
  import Accordion from "../../components/accordion/Accordion";
  export default {
    components: {
      Accordion,
    },
    data() {
      return {};
    },
  };
</script>
