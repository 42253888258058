<template>
  <!-- Start Footer Style Two  -->
  <div data-black-overlay="6" >
    <div class="wrapper plr--50 plr_sm--20">
      <v-row align="center">
        <v-col lg="4" md="4" sm="6" cols="12">
          <div class="inner">
            <div class="text-center text-sm-left mb_sm--20">
              <div class="logo">
                <img  style="width: auto; height: 61px;" src="../../assets/images/logo/buddy-dark.png" alt="Buddy Technologies Logo" />
              </div>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="4" sm="6" cols="12">
          <div class="inner text-center">
            <ul class="social-share rn-lg-size d-flex justify-center liststyle">
              <li v-for="(social, i) in socialList" :key="i">
                <a target="_blank" :href="social.url"><i class="fab" :class="social.icon"></i></a>
              </li>
            </ul>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="4" sm="12" cols="12">
          <div class="inner text-md-right text-center mt_md--20 mt_sm--20">
            <div class="text">
              <p style="font-weight: 500; font-size: 14px; color: #211863;">
                Copyright © 2022 - {{ new Date().getFullYear() }} Buddy Technologies
              </p>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
    </div>
  </div>
  <!-- End Footer Style Two  -->
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "#",
        },
        {
          icon: "fa-linkedin-in",
          url: "https://www.linkedin.com/company/buddy-technologies/",
        },
        {
          icon: "fa-instagram-square",
          url: "#",
        },
        {
          icon: "fa-whatsapp",
          url: "https://wa.me/56991427570?text=Hola%20tengo%20una%20idea%20que%20quiero%20desarrollar.",
        },
      ],
    };
  },
};
</script>
