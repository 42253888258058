<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePage>
      <img
        slot="logo"
        src="../../assets/images/logo/buddy-dark.png"
        class="logo-light"
      />
      <img
        slot="logo-dark"
        src="../../assets/images/logo/buddy-dark.png"
        class="logo-dark"
      />
    </HeaderOnePage>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div class="rn-slider-area poss_relative" id="home">
      <vue-particles
        color="#5c5b5b"
        :particleOpacity="0.7"
        :particlesNumber="40"
        shapeType="circle"
        :particleSize="4"
        linesColor="#5c5b5b"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="180"
        :moveSpeed="6"
        :hoverEffect="true"
        hoverMode="repulse"
        :clickEffect="false"
        clickMode="push"
      >
      </vue-particles>
      <div
        class="slide slide-style-2 bg_image bg_image--27 d-flex align-center"
      >
        <Banner>
          <h2 slot="heading-title" class="heading-title theme-gradient">
            SOFTWARE FACTORY
          </h2>
          <p slot="description" class="description" style="font-weight: 600; color:#5c5b5b; font-size: 18px;">
            Diseñamos software a medida para impulsar el crecimiento de tu empresa
          </p>
        </Banner>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Service Area  -->
    <div
      class="rn-service-area service-area rn-section-gap bg_color--1"
      id="service"
    >
      <v-container>
        <v-row>
          <v-col lg="6">
            <div class="section-title text-left mb--10">
              <h2 style="color: #211862;">Servicios</h2>
            </div>
          </v-col>
        </v-row>
        <ServiceThree />
      </v-container>
    </div>
    <!-- Start Service Area  -->

    <!-- Start About Area  -->
    <div class="rn-about-area rn-section-gap" id="about">
      <v-container>
        <AboutTwo />
      </v-container>
    </div>
    <!-- End About Area  -->

    <!-- Start Contact Area  -->
    <div class="rn-contact-area rn-section-gap" id="contact">
      <div class="contact-form--1">
        <v-container>
          <ContactAddress>
            <img
              slot="contact-img"
              class="w-100"
              src="../../assets/images/about/about-6.jpg"
              alt="trydo"
            />
          </ContactAddress>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import HeaderOnePage from "../../components/header/HeaderOnePage";
  import Banner from "../../components/slider/Banner";
  import AboutTwo from "../../components/about/AboutTwo.vue";
  import ServiceThree from "../../components/service/ServiceThree";
  import ContactAddress from "../../components/contact/ContactAddress.vue";
  import Footer from "../../components/footer/FooterTwo.vue";

  export default {
    components: {
      HeaderOnePage,
      Banner,
      AboutTwo,
      ServiceThree,
      ContactAddress,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>
