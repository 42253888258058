<template>
  <div>
    <v-expansion-panels
      accordion
      flat
      v-model="panel"
      mandatory
      class="about-expension-panels"
    >
      <v-expansion-panel v-for="(item, i) in accordionContent" :key="i">
        <v-expansion-panel-header style="color: #211862; font-weight: 600;" hide-actions>{{
          item.title
        }}</v-expansion-panel-header>
        <v-expansion-panel-content style="font-weight: 500; font-size: 14px; color: #5c5b5b;">
          {{ item.content }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panel: 0,
      accordionContent: [
        {
          title: "01. Escuchamos tus necesidades.",
          content: `La primera etapa de nuestro proceso de trabajo consiste en escuchar tus necesidades y objetivos para entender a fondo tu problema.`,
        },
        {
          title: "02. Te proponemos una solución.",
          content: `Una vez que entendemos tu problema, te proponemos una solución a medida que se adapte a tus necesidades y objetivos.`,
        },
        {
          title: "03. Desarrollamos la solución.",
          content: `Una vez que aceptas nuestra propuesta, desarrollamos la solución a medida que hemos acordado para resolver tu problema.`,
        },
        {
          title: "04. Probamos la solución.",
          content: ` Antes de entregarte la solución, la probamos para asegurarnos de que cumple con tus necesidades y objetivos.`,
        },
        {
          title: "05. Entregamos la solución.",
          content: `Finalmente, te entregamos la solución desarrollada y te brindamos soporte para garantizar su correcto funcionamiento.`,
        },
      ],
    };
  },
};
</script>
