<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2">
        <router-link to="#">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title" style="font-weight:600;">{{ service.title }}</h3>
              <p style="font-weight: 500; font-size: 14px;">
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "code",
            title: "Desarrollo de Software",
            desc: ` Desarrollamos soluciones de software a medida para impulsar la innovación y eficiencia en tu empresa.`,
          },
          {
            icon: "smartphone",
            title: "Aplicaciones Móviles",
            desc: ` Desarrollamos aplicaciones móviles para Android y iOS que se adaptan a las necesidades de tu empresa.`,
          },

          {
            icon: "cpu",
            title: "Automatización de Procesos",
            desc: `Automatizamos procesos manuales para mejorar la eficiencia y productividad de tu empresa.`,
          },
          {
            icon: "hard-drive",
            title: "Analítica de Datos",
            desc: `Analizamos tus datos para extraer información valiosa que te permita tomar decisiones informadas.`,
          },
          {
            icon: "users",
            title: "Mantenimiento y Soporte",
            desc: `Brindamos soporte y mantenimiento a tus sistemas para garantizar su correcto funcionamiento.`,
          },
          {
            icon: "monitor",
            title: "Consultoría Tecnológica",
            desc: ` Asesoramos a tu empresa en la adopción de tecnologías que impulsen su crecimiento.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
